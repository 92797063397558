import React, { useCallback, useMemo, useState } from 'react';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { selectOfflineInfo } from '../app/offline';
import superlogin from '../api/superlogin';
import { selectCurrentUserId, type ReduxState } from '../contexts/usersSlice';
import useAuth from '../hooks/useAuth';
import useLocationParams from '../hooks/useLocationParams';
import { LocationState } from './types';
import DisclaimerModal from '../components/DisclaimerModal';
import { agreeToDisclaimer } from '../api/auth';
import apm from '../lib/apm';
import { NEW_LOGIN_ACTIVE } from '../config';

import LoginFlowForm from '../elements/loginFlows/LoginFlowForm';

const SESSION_EXPIRED_MSG = 'Session expired.';
const LOGIN_MSG = 'Please log in.';
const LOGIN_WITH_OFFLINE_SYNC_MSG = 'Please log in with the same email to sync your offline work.';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
});

const Login = () => {
  const location = useLocation<LocationState>();
  const { searchParams } = useLocationParams(location);
  const hasActions = useSelector((state) => selectOfflineInfo(state).hasActions);
  const currentUserId = useSelector((state: ReduxState) => selectCurrentUserId(state));
  const [globalErrors, setGlobalErrors] = useState(null);
  const [redirectTrigger, setRedirectTrigger] = useState(false);
  const [loginInProgress, setloginInProgress] = useState(false);
  const { onAuthenticationSuccess, logout } = useAuth();
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [disclaimerText, setDisclamerText] = useState('');

  // The current redirect url if present, or undefined.
  const redirect = useMemo(() => searchParams.get('redirect'), [searchParams]);

  /**
   * A login prompt to show the user depending on various authentication scenarios.
   *
   * Eg, if a user's session has expired but they have unsaved offline work,
   * we need to show a message to prompt them to reauthenticate.
   */
  const flash = useMemo(() => {
    if (location.state && location.state.flash) {
      return location.state.flash || null;
    }
    if (!currentUserId || loginInProgress) {
      return null;
    }
    let flashMessage = SESSION_EXPIRED_MSG;
    if (hasActions) {
      flashMessage += ` ${LOGIN_WITH_OFFLINE_SYNC_MSG}`;
    } else {
      flashMessage += ` ${LOGIN_MSG}`;
    }
    return flashMessage;
  }, [location.state, currentUserId, loginInProgress, hasActions]);

  const onAuthenticationFailure = useCallback((error) => {
    setGlobalErrors(error.response?.data?.message || error.message);
  }, []);

  const login = useCallback(
    async (email, password) => {
      setGlobalErrors(null);
      setloginInProgress(true);
      try {
        const session = await superlogin.login({
          username: email,
          password,
        });
        if (NEW_LOGIN_ACTIVE) {
          await onAuthenticationSuccess(session);
          setRedirectTrigger(true);
        } else {
          if (session.disclaimer_text) {
            setDisclamerText(session.disclaimer_text);
            setShowDisclaimerModal(true);
          } else {
            await onAuthenticationSuccess(session);
            setRedirectTrigger(true);
          }
        }
      } catch (error) {
        onAuthenticationFailure(error);
      }
    },
    [onAuthenticationSuccess, onAuthenticationFailure]
  );

  const onAgree = useCallback(async () => {
    try {
      setShowDisclaimerModal(false);
      const session = await agreeToDisclaimer();
      await onAuthenticationSuccess(session);
      setRedirectTrigger(true);
    } catch (error) {
      onAuthenticationFailure(error);
    }
  }, [onAuthenticationSuccess, onAuthenticationFailure]);

  const onDecline = useCallback(() => {
    setloginInProgress(false);
    setShowDisclaimerModal(false);
    logout().catch((err) => apm.captureError(err));
  }, [logout]);

  const inputFields = useMemo(() => {
    return [
      {
        key: 'email',
        type: 'email',
        label: 'Email',
      },
      {
        key: 'password',
        type: 'password',
        label: 'Password',
        link: {
          label: 'Forgot password?',
          path: '/forgot-password',
        },
      },
    ];
  }, []);

  // Redirect to home page or original app path.
  if (redirectTrigger === true) {
    return <Redirect to={redirect || '/'} />;
  }

  return (
    <>
      {showDisclaimerModal && <DisclaimerModal disclaimer={disclaimerText} onAgree={onAgree} onDecline={onDecline} />}
      <LoginFlowForm
        heading="Log in"
        subHeading="Use your email to log in"
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={async (values) => login(values.email, values.password)}
        successMessage={flash}
        errorMessage={globalErrors}
        inputFields={inputFields}
      >
        {(isSubmitting) => (
          <>
            <button aria-label="Log in button" className="btn" type="submit" disabled={isSubmitting}>
              Log In
            </button>
            <Link
              to={(location) => ({
                // Preserve search url params when navigating through login flows.
                ...location,
                pathname: '/sso/provider',
              })}
              className="text-slate-600 underline text-center text-sm hover:brightness-75"
            >
              Log In with SSO
            </Link>
          </>
        )}
      </LoginFlowForm>
    </>
  );
};

export default Login;
