import ReviewTableInput from '../TableInput/ReviewTableInput';
import procedureUtil from '../../lib/procedureUtil';
import Button, { BUTTON_TYPES } from '../Button';
import React, { useCallback, useMemo, useState } from 'react';
import { DraftTableInputBlock } from 'shared/lib/types/views/procedures';
import { Dialog } from 'primereact/dialog';
import { Cell } from '../../hooks/useReferenceTokens';
import sharedTableUtil from 'shared/lib/tableUtil';

interface SelectTableCellModalProps {
  selectedTable: null | DraftTableInputBlock;
  onClose: () => void;
  onSelectCell: (cell: Cell) => void;
  isVisible: boolean;
}

const SelectTableCellModal = ({ selectedTable, onClose, onSelectCell, isVisible }: SelectTableCellModalProps) => {
  const [selectedCell, setSelectedCell] = useState<null | {
    rowId: string;
    columnId: string;
  }>(null);

  const { rowIndex: selectedRowIndex, columnIndex: selectedColumnIndex } = useMemo(() => {
    if (!selectedCell || !selectedTable) {
      return { rowIndex: -1, columnIndex: -1 };
    }
    return sharedTableUtil.cellIdsToIndices({
      rowId: selectedCell.rowId,
      columnId: selectedCell.columnId,
      rowMetadata: selectedTable.row_metadata,
      columnMetadata: selectedTable.columns,
    });
  }, [selectedTable, selectedCell]);

  const handleOnSelectCell = useCallback(() => {
    if (!selectedCell || !selectedTable || selectedRowIndex === -1 || selectedColumnIndex === -1) {
      return;
    }

    onSelectCell({
      rowId: selectedCell.rowId,
      columnId: selectedCell.columnId,
    });

    setSelectedCell(null);
    onClose();
  }, [selectedCell, selectedTable, selectedRowIndex, selectedColumnIndex, onSelectCell, onClose]);

  const handleOnClose = useCallback(() => {
    setSelectedCell(null);
    onClose();
  }, [onClose]);

  return (
    <Dialog
      header="Select Table Cell"
      onHide={handleOnClose}
      visible={isVisible}
      closable={false}
      className="w-5/6"
      footer={
        <div className="flex flex-col gap-y-2">
          <div className="mt-2 h-6 mr-2">
            {selectedCell && (
              <span className="p-1 border border-blue-300 bg-blue-100 rounded-md w-fit">
                {procedureUtil.displaySectionKey(selectedColumnIndex, 'letters')}
                {selectedRowIndex + 1} Selected
              </span>
            )}
          </div>
          <div className="flex flex-row gap-x-1 w-full justify-end">
            <Button type={BUTTON_TYPES.SECONDARY} ariaLabel="Close" isDisabled={false} onClick={handleOnClose}>
              Close
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              ariaLabel="Confirm"
              isDisabled={!selectedCell}
              onClick={handleOnSelectCell}
            >
              Confirm
            </Button>
          </div>
        </div>
      }
    >
      {selectedTable && (
        <div className="flex flex-col gap-2">
          <ReviewTableInput
            content={selectedTable}
            blockLabel=""
            isHidden={false}
            isSpacerHidden={false}
            onSelectCell={({ rowId, columnId }) => {
              setSelectedCell({
                rowId,
                columnId,
              });
            }}
            selectedCell={selectedCell}
            onContentRefChanged={undefined}
            scrollMarginTopValueRem={undefined}
          />
        </div>
      )}
    </Dialog>
  );
};

export default SelectTableCellModal;
