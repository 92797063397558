import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import projectUtil from '../lib/projectUtil';
import { useSettings } from './SettingsContext';

interface Props {
  children: React.ReactNode;
}

export type NavState = {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  projectId?: string;
  setProjectId: (projectId: string) => void;
  projectName?: string;
};

const shouldBeCollapsed = (): boolean => {
  return window.innerWidth < 1280;
};

const NavContext = React.createContext<undefined | NavState>(undefined);

const NavProvider = (props: Props) => {
  const { projects } = useSettings();
  const history = useHistory();
  const [isCollapsed, setStateIsCollapsed] = useState(shouldBeCollapsed());
  const [projectId, setStateProjectId] = useState<string | undefined>(() => {
    return localStorage.getItem('projectId') ?? undefined;
  });

  useEffect(() => {
    if (projectId) {
      localStorage.setItem('projectId', projectId);
    } else {
      localStorage.removeItem('projectId');
    }
  }, [projectId]);

  const setProjectId = useCallback((projectId: string) => {
    setStateProjectId(projectId);
  }, []);

  const setIsCollapsed = useCallback((isCollapsed: boolean) => {
    setStateIsCollapsed(isCollapsed);
  }, []);

  useEffect(() => {
    const initialSearchParams = new URLSearchParams(history.location.search);
    const urlProject = initialSearchParams.get('project-id');
    if (urlProject) {
      if (urlProject !== projectId) {
        setStateProjectId(urlProject);
      }
      initialSearchParams.delete('project-id');
      history.replace({
        search: initialSearchParams.toString(),
      });
    }
  }, [history, history.location.search, projectId]);

  const projectName = useMemo(() => {
    return projectId ? projectUtil.getProjectName(projects, projectId) : '';
  }, [projectId, projects]);

  return (
    <NavContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed,
        projectId,
        projectName,
        setProjectId,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
};

const useNavState = (): NavState => {
  const context = useContext(NavContext);

  if (context === undefined) {
    throw new Error('useNavState must be used within a NavProvider');
  }

  return context;
};

export { NavProvider, useNavState };
