import { useMemo, useCallback, Dispatch, SetStateAction, useState } from 'react';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { CouchLikeOperation } from 'shared/lib/types/operations';
import { ParentReferenceType, Participant, Release, Run } from 'shared/lib/types/views/procedures';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { History, Location } from 'history';
import RunStatusLabel from '../RunStatusLabel';
import runUtil, { PARTICIPANT_TYPE } from '../../lib/runUtil';
import AvatarStack from '../AvatarStack';
import DateTimeDisplay from '../DateTimeDisplay';
import { procedureViewPath, eventPath, procedureSnapshotViewPathWithSourceUrl } from '../../lib/pathUtil';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import Grid, { GridColumn } from '../../elements/Grid';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useUserInfo } from '../../contexts/UserContext';
import externalDataUtil from '../../lib/externalDataUtil';
import { INVALID_ITEMS_MESSAGE } from '../ButtonsProcedure';
import usePendingChangesPrompt from '../../hooks/usePendingChangesPrompt';
import _ from 'lodash';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import RunLabel from '../RunLabel';
import TruncatedColumn from '../../schedule/components/TruncatedColumn';
import { DateTime } from 'luxon';
import { SelectColumn, SortColumn } from 'react-data-grid';
import { compareEventStartTimes, processEventsWithLinkedProcedures } from './lib/operationUtil';
import { EventMap } from '../../screens/OperationDetail';
import procedureUtil from '../../lib/procedureUtil';
import RelativeScheduledDisplay from '../../schedule/components/RelativeScheduledDisplay';
import { LinkedProcedureRow, ProcedureMap, RunMap, StartProcedureParams } from './EventList/types';
import PlanningEventStatusColumn from './EventList/PlanningEventStatusColumn';
import Tooltip from '../../elements/Tooltip';
import Button, { BUTTON_TYPES } from '../Button';
import { StepCounts } from '../RunProgressBar';
import StatusColumn from '../StatusColumn/StatusColumn';
import NoDataPlaceholder from './NoDataPlaceholder';

const isLinkedProcedureRow = (row: Event | LinkedProcedureRow): row is LinkedProcedureRow => {
  return 'is_linked_procedure' in row && row.is_linked_procedure;
};

const nonViewerParticipants = (participants: Array<Participant>) => {
  const nonViewers = participants.filter((p) => {
    return p.type === PARTICIPANT_TYPE.PARTICIPATING;
  });
  return nonViewers.map((p) => p.user_id);
};

const MAIN_VERTICAL_PADDING = 190;

const DEFAULT_SORT: SortColumn = { columnKey: 'start', direction: 'ASC' };

interface OperationEventListProps {
  operation: CouchLikeOperation;
  procedures: Array<Release>;
  setProcedures: Dispatch<SetStateAction<Array<Release>>>;
  runs: Array<Run>;
  eventMap: EventMap;
  selectedRows: Set<Event['id']>;
  setSelectedRows: Dispatch<SetStateAction<Set<Event['id']>>>;
}

const OperationEventList = ({
  operation,
  procedures,
  setProcedures,
  runs,
  eventMap,
  selectedRows,
  setSelectedRows,
}: OperationEventListProps) => {
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const history = useHistory();
  const { mixpanel } = useMixpanel();
  const { userInfo } = useUserInfo();
  const location = useLocation();
  const userId = userInfo.session.user_id;
  const confirmPendingChanges = usePendingChangesPrompt();
  const [sortColumn, setSortColumn] = useState<SortColumn[]>([DEFAULT_SORT]);

  const mixpanelTrack = useCallback(
    (trackingKey: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingKey, properties);
      }
    },
    [mixpanel]
  );

  const procedureMap: ProcedureMap = useMemo(
    () => Object.fromEntries(procedures.map((proc) => [proc._id, proc])),
    [procedures]
  );

  const runMap: RunMap = useMemo(() => Object.fromEntries(runs.map((run) => [run._id, run])), [runs]);

  const isEventRowSelectable = useCallback(
    (row: Event | LinkedProcedureRow) => {
      const run = runMap[row.run_id || ''];
      if (!run) {
        return false;
      }
      return !runUtil.isCompleted(run);
    },
    [runMap]
  );

  const handleRowChange = useCallback(
    (eventId: Event['id']) => {
      const newSelectedRows = new Set(selectedRows);
      if (newSelectedRows.has(eventId)) {
        newSelectedRows.delete(eventId);
      } else {
        newSelectedRows.add(eventId);
      }
      setSelectedRows(newSelectedRows);
    },
    [selectedRows, setSelectedRows]
  );

  const handleRowsChange = useCallback(
    (rows: Set<Event['id']>) => {
      const selectableRows = new Set(
        Array.from(rows).filter((rowId) => {
          return isEventRowSelectable(eventMap[rowId]);
        })
      );
      if (selectableRows.size !== selectedRows.size) {
        setSelectedRows(selectableRows);
      } else {
        setSelectedRows(new Set());
      }
    },
    [selectedRows, setSelectedRows, isEventRowSelectable, eventMap]
  );

  // Creates a run doc and updates with dynamic data if online.
  const createRun = useCallback(
    async (
      procedure: Release,
      sectionId?: string,
      parentReferenceId = operation.key,
      parentReferenceType = ParentReferenceType.Operation
    ) => {
      const run = runUtil.newLinkedProcedureRunDoc({
        procedure,
        linkedSectionIndex: sectionId ? procedureUtil.getSectionIndexById(procedure, sectionId) : null,
        parentReferenceId,
        parentReferenceType,
        operation,
        userId,
      });
      try {
        const externalItems = await services.externalData.getAllExternalItems(run);
        return externalDataUtil.updateProcedureWithItems(run, externalItems);
      } catch (err) {
        // Ignore any errors and fall back to using procedure without dynamic data.
        return run;
      }
    },
    [services.externalData, userId, operation]
  );

  const addLinkedRunToParentRun = useCallback(
    async (parentRun: Run, linkedRunId: string, contentBlockId: string) => {
      for (const section of parentRun.sections) {
        for (const step of section.steps) {
          for (const content of step.content) {
            if (content.type === 'procedure_link' && content.id === contentBlockId && !content.run) {
              await services.runs.addLinkedRun(parentRun, section.id, step.id, content.id, linkedRunId);
              return;
            }
          }
        }
      }
    },
    [services.runs]
  );

  const startProcedure = useCallback(
    async ({
      procedure,
      sectionId,
      contentBlockId,
      parentReferenceId = operation.key,
      parentReferenceType = ParentReferenceType.Operation,
    }: StartProcedureParams) => {
      if (!operation || !(await confirmPendingChanges(procedure))) {
        return;
      }

      const run = await createRun(procedure, sectionId, parentReferenceId, parentReferenceType);
      const hasInvalidExternalItems = externalDataUtil.hasInvalidExternalItems(run);
      if (hasInvalidExternalItems && !window.confirm(INVALID_ITEMS_MESSAGE)) {
        return;
      }

      try {
        run.operation = _.pick(operation, 'name', 'key');
        setProcedures(procedures.concat(run));
        await services.runs.startRun(run);
        mixpanelTrack('Run Procedure', { Source: 'Operation Detail' });

        const parentRun = runMap[parentReferenceId || ''];
        if (parentRun && contentBlockId) {
          await addLinkedRunToParentRun(parentRun, run._id, contentBlockId);
        }
      } catch (err) {
        setProcedures(procedures);
      }
    },
    [
      procedures,
      confirmPendingChanges,
      createRun,
      mixpanelTrack,
      operation,
      services.runs,
      addLinkedRunToParentRun,
      setProcedures,
      runMap,
    ]
  );

  const handleSortChange = useCallback(
    (newSortColumn: SortColumn[]) => {
      if (newSortColumn.length === 0) {
        setSortColumn([]);
        return;
      }
      // if the column key is the same, just reverse the sort direction
      if (sortColumn.length === 0 || newSortColumn[0].columnKey === sortColumn[0].columnKey) {
        const direction = sortColumn.length === 0 ? 'DESC' : sortColumn[0].direction === 'ASC' ? 'DESC' : 'ASC';
        setSortColumn([{ ...newSortColumn[0], direction }]);
      } else {
        setSortColumn(newSortColumn);
      }
    },
    [sortColumn, setSortColumn]
  );

  /**
   * Since not all rows should be sorted (ie the nested rows), this is a custom sorting implementation
   * to handle the hierarchical event data:
   * 1. Sort only the root-level events (ignoring nested rows)
   * 2. Preserve the parent-child relationships by keeping nested rows with their parent events
   * 3. Reinsert the nested rows in their correct positions after sorting
   */
  const handleSortRows = useCallback(
    (rows: Array<Event | LinkedProcedureRow>) => {
      if (sortColumn.length === 0) {
        return rows;
      }
      const rootEvents = rows.filter((row): row is Event => !isLinkedProcedureRow(row) && !!row.is_root_event);
      const sortCol = sortColumn[0];
      const direction = sortCol.direction === 'ASC' ? 1 : -1;

      const sorted = [...rootEvents].sort((a, b) => {
        switch (sortCol.columnKey) {
          case 'event_name':
            return direction * a.name.localeCompare(b.name);
          case 'start':
            return direction * compareEventStartTimes(a, b, runMap);
          default:
            return 0;
        }
      });

      const sortedNestedEvents: Array<Event | LinkedProcedureRow> = [];
      if (sortCol.direction === 'ASC') {
        for (const event of sorted) {
          let eventIndex = rows.findIndex((row) => row.id === event.id);
          sortedNestedEvents.push(rows[eventIndex]);
          eventIndex += 1;
          while (eventIndex < rows.length && isLinkedProcedureRow(rows[eventIndex])) {
            sortedNestedEvents.push(rows[eventIndex]);
            eventIndex += 1;
          }
        }
      } else {
        for (const event of sorted) {
          let eventIndex = rows.findIndex((row) => row.id === event.id);
          const nestedGroup = [rows[eventIndex]];
          eventIndex -= 1;
          while (eventIndex >= 0 && isLinkedProcedureRow(rows[eventIndex])) {
            nestedGroup.push(rows[eventIndex]);
            eventIndex -= 1;
          }
          sortedNestedEvents.push(...nestedGroup);
        }
      }
      return sortedNestedEvents;
    },
    [runMap, sortColumn]
  );

  const sortedEvents = useMemo(() => {
    return Array.from(Object.values(eventMap)).sort((a, b) => compareEventStartTimes(a, b, runMap));
  }, [eventMap, runMap]);

  const eventsWithLinkedProcedures = useMemo(() => {
    return processEventsWithLinkedProcedures(sortedEvents);
  }, [sortedEvents]);

  const renderStatusColumn = useCallback(
    (event: Event) => {
      if (!event.procedure_id) {
        return <RunStatusLabel statusText={event.status || 'planning'} />;
      }
      const run = runMap[event.run_id || ''];
      if (run) {
        const stepCounts = runUtil.getRunStepCounts(run).runCounts as StepCounts;
        return <StatusColumn run={run} stepCounts={stepCounts} />;
      }
      return (
        <PlanningEventStatusColumn
          event={event}
          operation={operation}
          procedureMap={procedureMap}
          startProcedure={startProcedure}
        />
      );
    },
    [operation, procedureMap, startProcedure, runMap]
  );

  const renderDepthIndicators = useCallback((row: LinkedProcedureRow) => {
    return Array.from({ length: row.depth }, (_, index) => {
      const isRightMost = row.right_most_depths.includes(index);
      const marginLeft = `ml-[${index * 2}px]`;

      return (
        <div
          key={index}
          className={`
            flex w-2 border-blue-400 border-l-2 mr-1 ${marginLeft}
            ${isRightMost ? '-mt-3 h-3/4 border-b-2' : 'h-full'}
          `}
        />
      );
    });
  }, []);

  const renderEventName = useCallback(
    (event: Event, currentTeamId: string, history: History) => (
      <TruncatedColumn
        text={event.name}
        onClick={() => history.push(eventPath(currentTeamId, event.id), { from: 'operation' })}
      />
    ),
    []
  );

  const renderProcedureName = useCallback(
    (
      procedure: Release,
      currentTeamId: string,
      location: Location,
      operation: CouchLikeOperation,
      sectionId?: string
    ) => {
      const procedureLink = procedureSnapshotViewPathWithSourceUrl({
        teamId: currentTeamId,
        procedureId: procedure._id,
        sectionId: sectionId || '',
        sourceUrl: location.pathname,
        sourceName: `Operation ${operation.name}`,
      });

      return (
        <Tooltip content={procedure.code} visible={false}>
          <Link to={procedureLink} className=" font-medium ml-1 text-blue-600 hover:underline truncate">
            {procedure.name}
          </Link>
        </Tooltip>
      );
    },
    []
  );

  const renderProcedureStatus = useCallback(
    (
      procedure: Release,
      row: LinkedProcedureRow,
      operation: CouchLikeOperation,
      currentTeamId: string,
      location: Location
    ) => {
      const MAX_PROCEDURE_CODE_LENGTH = 18;
      const truncatedProcedureCode =
        procedure.code.length > MAX_PROCEDURE_CODE_LENGTH
          ? `${procedure.code.substring(0, MAX_PROCEDURE_CODE_LENGTH)}...`
          : procedure.code;
      const procedureLink = procedureSnapshotViewPathWithSourceUrl({
        teamId: currentTeamId,
        procedureId: row.procedure_id || '',
        sourceUrl: location.pathname,
        sourceName: `Operation ${operation.name}`,
      });

      const parentRun = runMap[row.parent_run_id || ''];
      const isDisabled = operation.state === 'ended' || !row.parent_run_id || parentRun?.state !== 'running';

      return (
        <div className="flex flex-row items-center text-base">
          <Button
            type={BUTTON_TYPES.PRIMARY}
            size="sm"
            onClick={() =>
              startProcedure({
                procedure,
                sectionId: row.section_id,
                contentBlockId: row.content_block_id,
                parentReferenceId: row.parent_run_id,
                parentReferenceType: ParentReferenceType.Run,
              })
            }
            isDisabled={isDisabled}
          >
            Start
          </Button>
          <Tooltip content={procedure.code} visible={truncatedProcedureCode !== procedure.code}>
            <Link to={procedureLink} className="text-sm ml-2 text-blue-600 hover:underline truncate">
              {truncatedProcedureCode}
            </Link>
          </Tooltip>
        </div>
      );
    },
    [startProcedure, runMap]
  );

  const columns: GridColumn<Event | LinkedProcedureRow>[] = useMemo(
    () => [
      ...(operation.state === 'running'
        ? [
            {
              ...SelectColumn,
              renderHeaderCell: () => {
                const selectableRowIds = new Set<Event['id']>();
                for (const event of eventsWithLinkedProcedures) {
                  if (isEventRowSelectable(event)) {
                    selectableRowIds.add(event.id);
                  }
                }
                const isDisabled = selectableRowIds.size === 0;
                return (
                  <input
                    type="checkbox"
                    className={`rdg-checkbox-input ${isDisabled ? 'bg-gray-200 border-gray-400' : 'cursor-pointer'}`}
                    disabled={isDisabled}
                    onChange={() => handleRowsChange(selectableRowIds)}
                    checked={selectableRowIds.size !== 0 && selectedRows.size === selectableRowIds.size}
                  />
                );
              },
              renderCell: ({ row }: { row: Event | LinkedProcedureRow }) => {
                const isDisabled = !isEventRowSelectable(row);
                const isChecked = selectedRows.has(row.id);
                return (
                  <input
                    type="checkbox"
                    className={`rdg-checkbox-input ${isDisabled ? 'bg-gray-200 border-gray-400' : 'cursor-pointer'}`}
                    disabled={isDisabled}
                    checked={isChecked}
                    onChange={() => handleRowChange(row.id)}
                  />
                );
              },
            },
          ]
        : []),
      {
        key: 'event_name',
        name: 'Event',
        width: operation.state === 'running' ? '19%' : '20%',
        sortable: true,
        renderCell: ({ row }: { row: Event | LinkedProcedureRow }) => {
          if (!isLinkedProcedureRow(row)) {
            return renderEventName(row, currentTeamId, history);
          }

          if (row.event_id) {
            const event = eventMap[row.event_id];
            return (
              <>
                {renderDepthIndicators(row)}
                {event ? renderEventName(event, currentTeamId, history) : <NoDataPlaceholder />}
              </>
            );
          }

          const procedure = procedureMap[row.procedure_id];
          return (
            <>
              {renderDepthIndicators(row)}
              {procedure ? (
                renderProcedureName(procedure, currentTeamId, location, operation, row.section_id)
              ) : (
                <NoDataPlaceholder />
              )}
            </>
          );
        },
        // stubbed out so the sorting post processor is called
        comparator: (a: Event, b: Event) => {
          return 1;
        },
      },
      {
        key: 'start',
        name: 'Start',
        width: '22%',
        sortable: true,
        renderCell: ({ row }: { row: Event | LinkedProcedureRow }) => {
          if (isLinkedProcedureRow(row) && !row.run_id) {
            return <NoDataPlaceholder />;
          }
          const run = runMap[row.run_id || ''];
          if (run) {
            return (
              <div className="text-sm">
                <DateTimeDisplay timestamp={run.starttime} wrap={true} hasTooltip={true} zone="UTC" />
                <span> UTC</span>
              </div>
            );
          }
          const event = isLinkedProcedureRow(row) ? eventMap[row.event_id || ''] : row;
          if (event?.predecessor_id) {
            const predecessor = eventMap[event.predecessor_id];
            if (predecessor) {
              return (
                <div className="text-sm text-gray-400">
                  <RelativeScheduledDisplay
                    teamId={currentTeamId}
                    event={event}
                    predecessorEvent={predecessor}
                    predecessorOffset={event.predecessor_offset}
                    from="operation"
                  />
                </div>
              );
            }
          }
          if (event?.start) {
            return (
              <div className="text-sm text-gray-400">
                <span>(</span>
                <DateTimeDisplay
                  timestamp={(event.start as DateTime).toUTC()}
                  wrap={true}
                  hasTooltip={true}
                  zone="UTC"
                />
                <span> UTC</span>
                <span>)</span>
              </div>
            );
          }
          return <NoDataPlaceholder />;
        },
        // stubbed out so the sorting post processor is called
        comparator: (a: Event, b: Event) => {
          return 1;
        },
      },
      {
        key: 'end',
        name: 'End',
        width: '22%',
        renderCell: ({ row }: { row: Event | LinkedProcedureRow }) => {
          if (isLinkedProcedureRow(row) && !row.run_id) {
            return <NoDataPlaceholder />;
          }
          const run = runMap[row.run_id || ''];
          if (run && run.completedAt) {
            return (
              <div className="text-sm">
                <DateTimeDisplay timestamp={run.completedAt} wrap={true} hasTooltip={true} zone="UTC" />
                <span> UTC</span>
              </div>
            );
          }
          const event = isLinkedProcedureRow(row) ? eventMap[row.event_id || ''] : row;
          if (event?.end) {
            return (
              <div className="text-sm text-gray-400">
                <span>(</span>
                <DateTimeDisplay timestamp={(event.end as DateTime).toUTC()} wrap={true} hasTooltip={true} zone="UTC" />
                <span> UTC</span>
                <span>)</span>
              </div>
            );
          }
          return <NoDataPlaceholder />;
        },
      },
      ...(operation.state !== 'planning'
        ? [
            {
              key: 'participants',
              name: 'Participants',
              width: '11%',
              renderCell: ({ row }: { row: Event | LinkedProcedureRow }) => {
                const run = runMap[row.run_id || ''];
                if (!run) {
                  return <NoDataPlaceholder />;
                }
                return <AvatarStack userIds={run.participants ? nonViewerParticipants(run.participants) : []} />;
              },
            },
            {
              key: 'status',
              name: 'Status',
              width: operation.state === 'running' ? '22%' : '25%',
              renderCell: ({ row }: { row: Event | LinkedProcedureRow }) => {
                if (!isLinkedProcedureRow(row)) {
                  return renderStatusColumn(row);
                }

                if (row.event_id) {
                  const event = eventMap[row.event_id || ''];
                  return event ? renderStatusColumn(event) : <NoDataPlaceholder />;
                }

                const procedure = procedureMap[row.procedure_id];
                return procedure ? (
                  renderProcedureStatus(procedure, row, operation, currentTeamId, location)
                ) : (
                  <NoDataPlaceholder />
                );
              },
            },
          ]
        : [
            {
              key: 'procedure',
              name: 'Procedure',
              renderCell: ({ row }: { row: Event | LinkedProcedureRow }) => {
                if (isLinkedProcedureRow(row) || !row.procedure_id) {
                  return <NoDataPlaceholder />;
                }
                const linkPath = procedureViewPath(currentTeamId, row.procedure_id);
                const procedure = procedureMap[row.procedure_id];
                if (!procedure) {
                  return <NoDataPlaceholder />;
                }
                return (
                  <div className="leading-4">
                    {procedure.code && <RunLabel code={procedure.code} name={procedure.name} link={linkPath} />}
                  </div>
                );
              },
            },
          ]),
    ],
    [
      currentTeamId,
      operation,
      history,
      procedureMap,
      runMap,
      selectedRows,
      handleRowChange,
      isEventRowSelectable,
      handleRowsChange,
      eventsWithLinkedProcedures,
      eventMap,
      location,
      renderDepthIndicators,
      renderEventName,
      renderProcedureName,
      renderProcedureStatus,
      renderStatusColumn,
    ]
  );

  if (!procedures || !operation || !sortedEvents) {
    return null;
  }

  return (
    <Grid
      key={columns.length} // Force RDG to re-render columns when op starts
      columns={columns}
      rows={eventsWithLinkedProcedures}
      usedVerticalSpace={() => MAIN_VERTICAL_PADDING}
      defaultSort={sortColumn}
      onSortColumnsChange={handleSortChange}
      sortPostProcessor={handleSortRows}
      selectedRows={selectedRows}
      onSelectedRowsChange={handleRowsChange}
      rowKeyGetter={(row: Event | LinkedProcedureRow) => row.id}
    />
  );
};

export default OperationEventList;
