export const PRO_ENABLED_KEY = 'PRO_ENABLED';

// core features
export const STORAGE_ENABLED_KEY = 'ANALYTICS_ENABLED';
export const AUTOMATION_UI_ENABLED_KEY = 'AUTOMATION_UI_ENABLED';
export const INSIGHTS_ENABLED_KEY = 'INSIGHTS_ENABLED';
export const INVENTORY_ENABLED_KEY = 'INVENTORY_ENABLED';
export const ISSUES_ENABLED_KEY = 'ISSUES_ENABLED';
export const PLANNING_ENABLED_KEY = 'PLANNING_ENABLED';
export const TEST_CONDITIONS_MATRIX_ENABLED_KEY =
  'TEST_CONDITIONS_MATRIX_ENABLED';

// released integrations (consider removing)
export const JAMA_INTEGRATION_ENABLED = 'JAMA_INTEGRATION_ENABLED';
export const JIRA_INTEGRATION_ENABLED_KEY = 'JIRA_INTEGRATION_ENABLED';
export const QUICKBOOKS_INTEGRATION_ENABLED_KEY =
  'QUICKBOOKS_INTEGRATION_ENABLED';

// experimental
export const DEV_TELEMETRY_BULK_FETCH = 'DEV_TELEMETRY_BULK_FETCH';
export const FULL_DIFF_REVIEW_DISABLED_KEY = 'FULL_DIFF_REVIEW_DISABLED';
export const IMPORTEXPORT_ENABLED_KEY = 'IMPORTEXPORT_ENABLED';
export const SLACK_INTEGRATION_ENABLED_KEY = 'SLACK_INTEGRATION_ENABLED';

// dev
export const DEV_NEW_NOTIFICATIONS_DISABLED_KEY =
  'DEV_NEW_NOTIFICATIONS_DISABLED';
export const RISKS_ENABLED_KEY = 'RISKS_ENABLED';
export const TEST_MANAGEMENT_VERSIONS_REVIEW_KEY =
  'TEST_MANAGEMENT_VERSIONS_REVIEW';
export const PART_UNITS_ENABLED_KEY = 'PART_UNITS_ENABLED';
export const SUBSTEP_SYNC_DEBOUNCE = 'SUBSTEP_SYNC_DEBOUNCE';
export const AI_PROCEDURE_GENERATION_ENABLED =
  'AI_PROCEDURE_GENERATION_ENABLED';
export const POSTGRES_ONLY_ENABLED = 'POSTGRES_ONLY_ENABLED';
export const GLOBAL_TAGS_ENABLED = 'GLOBAL_TAGS_ENABLED';
